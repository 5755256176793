<template>
  <div class="main-container">
    <TabMenu v-model:activeIndex="activeTabIndex" :model="menuItems" />

    <div v-if="!location && activeTabIndex === 0" class="welcome-text">
      <p>
        Vítejte v aplikaci <b>"Obleč se správně"!</b> Tato aplikace je tu pro
        ty, jejichž děti každé ráno přemýšlejí, jak se správně obléknout.
        Zvláště pro takové, které by i v zimě nejraději vyrazily ven v plavkách.
        Je ideální pro situace, kdy vaše děti nedokážou samy posoudit, co je
        vhodné oblečení podle počasí, což může být obzvlášť užitečné pro děti s
        poruchou autistického spektra.
      </p>

      <p>
        Nezapomeňte prosím věnovat chvilku
        <b><a href="#" @click="switchToSettingsTab">nastavení</a></b> aplikace,
        abyste získali co nejpřesnější doporučení. Stačí zadat vaši lokaci a
        upravit preference oblečení. Můžete vybrat, které oblečení mají být
        zobrazeny v závislosti na teplotě, a také specifikovat, zda chcete
        zobrazovat oblečení pro kluky, dívky, nebo obojí. Tato personalizace vám
        pomůže zajistit, že oblečení bude vždy perfektně odpovídat počasí a
        potřebám vašeho dítěte.
      </p>
      <div style="display: flex; justify-content: center; align-items: center">
        <Button label="Přejít do nastavení" @click="switchToSettingsTab" />
      </div>
    </div>
    <keep-alive>
      <WeatherPanel v-if="activeTabIndex === 0" :settings="settings" />
    </keep-alive>

    <SettingsPanel
      v-if="activeTabIndex === 1"
      :settings="settings"
      :deferred-prompt="deferredPrompt"
      @onSettingsChange="onSettingsChange"
      @showSettings="switchToSettingsTab"
      @promptInstall="promptInstall"
    />

    <AboutPanel v-if="activeTabIndex === 2" />
  </div>
</template>

<script>
import SettingsPanel from './components/SettingsPanel.vue';
import AboutPanel from './components/AboutPanel.vue';
import Button from 'primevue/button';
import axios from 'axios';
import { Storage } from './storage';
import WeatherPanel from './components/WeatherPanel.vue';
import TabMenu from 'primevue/tabmenu';
import { useToast } from 'primevue/usetoast';

export default {
  name: 'App',
  components: {
    TabMenu,
    AboutPanel,
    Button,
    SettingsPanel,
    WeatherPanel
  },
  data() {
    return {
      settings: null,
      menuItems: [
        {
          label: 'Počasí',
          icon: 'pi pi-fw pi-home'
        },
        {
          label: 'Nastavení',
          icon: 'pi pi-fw pi-cog'
        },
        {
          label: 'O aplikaci',
          icon: 'pi pi-fw pi-info-circle'
        }
      ],
      deferredPrompt: null,
      activeTabIndex: 0,
      toast: null
    };
  },
  computed: {
    location() {
      return this.settings?.location;
    }
  },
  mounted() {
    this.toast = useToast();
    window.addEventListener(
      'beforeinstallprompt',
      this.handleBeforeInstallPrompt
    );
    this.settings = Storage.loadSettings();
    this.fetchData();
  },
  beforeUnmount() {
    window.removeEventListener(
      'beforeinstallprompt',
      this.handleBeforeInstallPrompt
    );
  },
  methods: {
    onSettingsChange(settings) {
      this.settings = { ...this.settings, ...settings };
      Storage.saveSettings(settings);
    },
    async fetchData() {
      const { data } = await axios.get('/.netlify/functions/clothes');
      if (this.settings.clothes) {
        const newClothes = [];
        data.forEach((item) => {
          const existingItem = this.settings.clothes.find(
            (i) => i.image === item.image
          );
          if (existingItem) {
            newClothes.push(existingItem);
          } else {
            item.active = Boolean(item.active);
            newClothes.push(item);
          }
        });
        this.settings.clothes = newClothes;
      } else {
        const newClothes = data.map((item) => {
          item.active = Boolean(item.active);
          return item;
        });
        this.settings.clothes = newClothes;
      }
    },
    switchToSettingsTab() {
      this.activeTabIndex = 1;
    },
    handleBeforeInstallPrompt(event) {
      this.deferredPrompt = event;
      event.preventDefault();
    },
    promptInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();

        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            this.toast.add({
              severity: 'info',
              detail: 'Aplikaci se nepodařilo nainstalovat.',
              life: 3000
            });
          } else {
            this.toast.add({
              severity: 'error',
              summary: 'Chyba',
              detail: 'Aplikaci se nepodařilo nainstalovat.',
              life: 3000
            });
          }
          this.deferredPrompt = null; // Resetovat deferredPrompt po rozhodnutí uživatele
        });
      } else {
        console.log('deferredPrompt is null.');
      }
    }
  }
};
</script>

<style>
#app {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Playpen Sans', sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.welcome-text {
  max-width: 900px;
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.5;
  padding: 3px;
  text-align: justify;
}

@media (min-width: 600px) {
  .welcome-text {
    font-size: 18px;
  }
}

@media (min-width: 900px) {
  .welcome-text {
    font-size: 20px;
  }
}
</style>
