const defaultSettings = {
  temperature: 'celsius',
  useFeelLikeTemp: true,
  location: null,
};

export class Storage {
  static saveSettings(settings) {
    localStorage.setItem('settings', JSON.stringify(settings));
  }
  static loadSettings() {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    if (!savedSettings) {
      return defaultSettings;
    }
    return savedSettings;
  }
}
