<template>
  <div class="settings-panel">
    <Dialog
      v-model:visible="displayIosInstallDialog"
      header="Návod na instalaci PWA na iOS"
      :visible="displayIosInstallDialog"
    >
      <ol>
        <li>Klepněte na ikonu sdílení (čtverec se šipkou nahoru).</li>
        <li>Přejděte dolů a klepněte na "Přidat na plochu".</li>
        <li>Potvrďte přidání klepnutím na "Přidat" v pravém horním rohu.</li>
        <li>Nyní můžete aplikaci otevřít z plochy.</li>
      </ol>
      <div style="display: flex; justify-content: center; align-items: center">
        <Button
          type="button"
          label="OK"
          severity="primary"
          @click="displayIosInstallDialog = false"
        ></Button>
      </div>
    </Dialog>
    <h2>Lokalita</h2>
    <LocationPicker
      :selected-item="settings.location"
      @onLocationChange="onLocationChange"
    />
    <h2 class="section-heading">Nastavení oblečení pro dané teploty</h2>
    <div class="choose-block">
      <span class="label">Zobrazovat oblečení pro: </span>
      <SelectButton
        v-model="preset"
        :options="presets"
        option-label="label"
        option-value="value"
        aria-labelledby="basic"
      />
    </div>
    <div class="choose-block">
      <span class="label">Zobrazovat teplotu: </span>
      <SelectButton
        v-model="tempType"
        :options="tempTypes"
        option-label="label"
        option-value="value"
        aria-labelledby="basic"
      />
    </div>
    <div class="install-button">
      <Button
        v-if="canInstallPWA"
        label="Instalovat aplikaci"
        @click="promptInstall"
      />
      <Button
        v-if="isIosDevice"
        label="Přidat aplikaci na plochu"
        @click="showIosInstallGuide"
      />
    </div>
    <div v-if="true" class="clothes">
      <div class="clothes-container">
        <div v-for="clothe in clothes" :key="clothe.name" class="clothe-item">
          <img
            :src="`/images/clothes/${clothe.image}`"
            :alt="clothe.image"
            class="clothe-preview"
          />
          <div class="clothe-details">
            <div class="clothe-name">
              <img
                v-if="clothe.rainOnly"
                src="https://openweathermap.org/img/wn/09d.png"
                class="weather-icon"
              />
              {{ clothe.name }}
            </div>
            <div class="temperature-range">
              <div class="temperature-box">
                <div class="input-with-label">
                  <span>Od:</span>
                  <InputNumber
                    v-model="clothe.tempMin"
                    show-buttons
                    button-layout="vertical"
                    class="temperature-input"
                    suffix="℃"
                    :min="MIN_TEMPERATURE"
                    :max="MAX_TEMPERATURE"
                    @update:model-value="settingsChange"
                  >
                    <template #incrementbuttonicon>
                      <span class="pi pi-plus" />
                    </template>
                    <template #decrementbuttonicon>
                      <span class="pi pi-minus" />
                    </template>
                  </InputNumber>
                </div>
                <div class="input-with-label">
                  <span>Do:</span>
                  <InputNumber
                    v-model="clothe.tempMax"
                    show-buttons
                    button-layout="vertical"
                    class="temperature-input"
                    suffix="℃"
                    :min="MIN_TEMPERATURE"
                    :max="MAX_TEMPERATURE"
                    @update:model-value="settingsChange"
                  >
                    <template #incrementbuttonicon>
                      <span class="pi pi-plus" />
                    </template>
                    <template #decrementbuttonicon>
                      <span class="pi pi-minus" />
                    </template>
                  </InputNumber>
                </div>
              </div>
            </div>
            <div class="display-in-forecast">
              Zobrazovat v předpovědi
              <InputSwitch
                v-model="clothe.active"
                @update:model-value="settingsChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationPicker from './LocationPicker.vue';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import { cloneDeep } from 'lodash';
import { MIN_TEMPERATURE, MAX_TEMPERATURE } from '../constants';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Dialog from 'primevue/dialog';
export default {
  name: 'SettingsPanel',
  components: {
    Button,
    LocationPicker,
    InputSwitch,
    InputNumber,
    SelectButton,
    Dialog
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    deferredPrompt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      presets: [
        { label: 'Kluk', value: 'boy' },
        { label: 'Holka', value: 'girl' },
        { label: 'Obojí', value: 'both' }
      ],
      tempTypes: [
        { label: 'Skutečná', value: 'temp' },
        { label: 'Pocitová', value: 'feels_like' }
      ],
      preset: this.settings?.preset || null,
      tempType: this.settings?.tempType || 'feels_like',
      displayIosInstallDialog: false,
      MIN_TEMPERATURE,
      MAX_TEMPERATURE
    };
  },
  computed: {
    clothes() {
      const result = this.settings?.clothes ? [...this.settings.clothes] : [];
      result.sort((a, b) => a.type - b.type);
      return result;
    },
    isIosDevice() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
    canInstallPWA() {
      // Check if the beforeinstallprompt event was fired
      const canInstall = this.deferredPrompt !== null;
      console.log('canInstall', canInstall, this.deferredPrompt);

      return canInstall && !this.isIosDevice;
    }
  },
  watch: {
    tempType(newValue) {
      const newSettings = { ...this.settings, tempType: newValue };
      this.$emit('onSettingsChange', newSettings);
    },
    preset(newValue) {
      for (const clothe of this.clothes) {
        if (!clothe.gender) {
          clothe.active = true;
        } else {
          clothe.active = clothe.gender === newValue || newValue === undefined;
        }
      }
      const newSettings = { ...this.settings, preset: newValue };
      this.$emit('onSettingsChange', newSettings);
    }
  },
  mounted() {
    window.addEventListener(
      'beforeinstallprompt',
      this.handleBeforeInstallPrompt
    );

    if (!this.settings) {
      const settings = cloneDeep(this.initsettings);
      this.$emit('onSettingsChange', settings);
    }
  },
  beforeUnmount() {
    window.removeEventListener(
      'beforeinstallprompt',
      this.handleBeforeInstallPrompt
    );
  },
  methods: {
    onLocationChange(location) {
      const newSettings = { ...this.settings, location };
      this.$emit('onSettingsChange', newSettings);
    },
    promptInstall() {
      this.$emit('promptInstall');
    },
    showIosInstallGuide() {
      this.displayIosInstallDialog = true;
    },
    settingsChange() {
      const newSettings = { ...this.settings, clothes: this.clothes };
      this.$emit('onSettingsChange', newSettings);
    }
  }
};
</script>

<style scoped>
.settings-panel {
  padding: 20px;
}

.section-heading {
  padding-top: 15px;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.choose-block {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: column;
}

.label {
  margin-right: 10px;
  font-weight: bold;
}

.install-button {
  margin: 20px 0;
}

.clothes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.clothe-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.input-with-label {
  min-width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.temperature-box {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.temperature-input {
  width: 5rem;
}

.display-in-forecast {
  margin-top: 10px;
  width: 100%;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.clothe-preview {
  max-width: 100px;
  height: auto;
  border-radius: 5px;
}

.clothe-details {
  margin-left: 15px;
}

.clothe-name {
  font-weight: bold;
}

.temperature-range {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weather-icon {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

@media (min-width: 600px) {
  .clothe-item {
    width: calc(50% - 20px); /* Dva sloupce na tabletu a větší */
  }
}

@media (min-width: 900px) {
  .clothe-item {
    width: calc(33.333% - 20px); /* Tři sloupce na velké obrazovce */
  }
}
</style>
