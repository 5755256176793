<template>
  <div v-if="weatherData" class="weather-panel">
    <div class="temp-with-icon">
      <span v-if="temp !== null" class="temp">{{ temp }} °C</span>
      <span v-else class="temp">-</span>
      <span class="icon"><img :src="weatherData.weatherImage" /></span>
    </div>
    <div class="clothes-container">
      <div v-if="!grouped">
        <div
          v-for="clothe in suitableClothes"
          :key="clothe.image"
          class="clothe"
        >
          <img :src="clothe.image" :alt="clothe.name" />
        </div>
      </div>
      <div v-else>
        <div
          v-for="group in suitableClothesGroupped"
          :key="group[0].type"
          class="group"
        >
          <div v-for="clothe in group" :key="clothe.image" class="clothe">
            <img :src="'/images/clothes/' + clothe.image" :alt="clothe.name" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { rainWeatherIds } from '../constants';
import { MIN_TEMPERATURE, MAX_TEMPERATURE } from '../constants';
export default {
  name: 'WeatherPanel',
  components: {},
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      weatherData: null,
      polling: null,
      grouped: true
    };
  },
  computed: {
    clothes() {
      const result = this.settings?.clothes || [];
      result.sort((a, b) => {
        if (a.type !== b.type) {
          return a.type - b.type;
        } else {
          return a.layer - b.layer;
        }
      });
      return result;
    },
    temp() {
      const temp =
        this.settings?.tempType === 'temp'
          ? this.weatherData?.temp
          : this.weatherData?.feelLikeTemp;
      return temp !== null ? Math.round(temp) : null;
    },
    activeClothes() {
      return this.clothes.filter((clothe) => clothe.active);
    },
    suitableClothes() {
      return this.activeClothes.filter((clothe) => {
        const tempMin =
          clothe.tempMin <= MIN_TEMPERATURE ? -Infinity : clothe.tempMin;
        const tempMax =
          clothe.tempMax >= MAX_TEMPERATURE ? Infinity : clothe.tempMax;
        const tempOk = tempMin <= this.temp && this.temp <= tempMax;
        if (clothe.rainOnly) {
          return (
            tempOk &&
            this.weatherData?.weatherId &&
            rainWeatherIds.includes(this.weatherData.weatherId)
          );
        }

        return tempOk;
      });
    },
    suitableClothesGroupped() {
      const groupedClothes = [];
      const types = new Set(this.suitableClothes.map((clothe) => clothe.type));
      types.forEach((type) => {
        const clothesOfType = this.suitableClothes.filter(
          (clothe) => clothe.type === type
        );
        groupedClothes.push(clothesOfType);
      });
      return groupedClothes;
    },
    position() {
      return this.settings?.location;
    },
    lat() {
      return this.settings?.location?.position?.lat;
    }
  },
  watch: {
    position: {
      handler(value) {
        if (!value) {
          return;
        }
        this.fetchWeather();
        if (!this.polling) {
          this.startPolling();
        }
      },
      deep: true,
      immediate: true
    },
    temp(newTemp) {
      const tempElement = document.getElementById('temperature');
      if (!tempElement) {
        return;
      }
      const range = 70; // Celkový rozsah od -30 do +40 je 70 stupňů
      const percentage = (newTemp + 30) / range; // Převod teploty na procentuální hodnotu

      // Nastavení výšky výplně
      tempElement.style.height = `${percentage * 100}%`;

      // Nastavení barvy výplně
      if (newTemp >= 0) {
        tempElement.style.backgroundColor = 'red';
      } else {
        tempElement.style.backgroundColor = 'blue';
      }
    }
  },
  beforeUnmount() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
  methods: {
    async fetchWeather() {
      if (!this.settings?.location?.position) {
        return;
      }
      try {
        const { lat, lon } = this.settings.location.position;
        const { data } = await axios.get(
          `/.netlify/functions/weather?lat=${lat}&lon=${lon}`
        );
        this.weatherData = data;
      } catch (error) {
        console.error('fetchWeather', error);
        clearInterval(this.polling);
      }
    },
    async startPolling() {
      await this.fetchWeather();

      this.polling = setInterval(await this.fetchWeather, 1000 * 60 * 15);
    }
  }
};
</script>

<style scoped>
.weather-panel {
  /* Your component styles here */
}

.clothe img {
  max-width: 110px;
  max-height: 110px;
  width: auto;
  height: auto;
}

.group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.clothes-container {
  margin-top: -40px;
}

.temp-with-icon {
  display: flex;
  align-items: center; /* Zarovná prvky vertikálně na střed */
  justify-content: center; /* Zarovná prvky horizontálně na střed */
  margin-top: -30px;
}

.temp-with-icon .temp {
  font-size: 70px; /* Nastaví velikost písma teploty, aby odpovídala velikosti ikony */
  font-weight: bold; /* Zvýraznění textu pro lepší čitelnost */
  margin-right: 20px; /* Přidává mezery mezi teplotou a ikonou */
}

.temp-with-icon .icon img {
  width: 150px; /* Nastaví šířku ikony */
  height: 150px; /* Nastaví výšku ikony */
}

.welcome-text {
  max-width: 500px;
  font-size: 18px;
}
</style>
