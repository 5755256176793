export const weatherTypes = {
  200: { description: 'bouřka s lehkým deštěm', icon: '11d' },
  201: { description: 'bouřka s deštěm', icon: '11d' },
  202: { description: 'bouřka s prudkým deštěm', icon: '11d' },
  210: { description: 'lehká bouřka', icon: '11d' },
  211: { description: 'bouřka', icon: '11d' },
  212: { description: 'prudká bouřka', icon: '11d' },
  221: { description: 'bouřka', icon: '11d' },
  230: { description: 'bouřka s lehkým mrholením', icon: '11d' },
  231: { description: 'bouřka s mrholením', icon: '11d' },
  232: { description: 'bouřka s prudkým mrholením', icon: '11d' },
  300: { description: 'slabé mrholení', icon: '09d' },
  301: { description: 'mrholení', icon: '09d' },
  302: { description: 'silné mrholení', icon: '09d' },
  310: { description: 'slabé mrholení s deštěm', icon: '09d' },
  311: { description: 'mrholení s deštěm', icon: '09d' },
  312: { description: 'silné mrholení s deštěm', icon: '09d' },
  313: { description: 'přeháňky a mrholení', icon: '09d' },
  314: { description: 'silné přeháňky a mrholení', icon: '09d' },
  321: { description: 'přeháňky mrholení', icon: '09d' },
  500: { description: 'lehký déšť', icon: '10d' },
  501: { description: 'mírný déšť', icon: '10d' },
  502: { description: 'silný déšť', icon: '10d' },
  503: { description: 'velmi silný déšť', icon: '10d' },
  504: { description: 'extrémní déšť', icon: '10d' },
  511: { description: 'mrznoucí déšť', icon: '13d' },
  520: { description: 'lehké přeháňky', icon: '09d' },
  521: { description: 'přeháňky', icon: '09d' },
  522: { description: 'silné přeháňky', icon: '09d' },
  531: { description: 'nepravidelné přeháňky', icon: '09d' },
  600: { description: 'lehké sněžení', icon: '13d' },
  601: { description: 'sněžení', icon: '13d' },
  602: { description: 'silné sněžení', icon: '13d' },
  611: { description: 'déšť se sněhem', icon: '13d' },
  612: { description: 'lehké přeháňky déště se sněhem', icon: '13d' },
  613: { description: 'přeháňky déště se sněhem', icon: '13d' },
  615: { description: 'lehký déšť a sníh', icon: '13d' },
  616: { description: 'déšť a sníh', icon: '13d' },
  620: { description: 'lehké sněhové přeháňky', icon: '13d' },
  621: { description: 'sněhové přeháňky', icon: '13d' },
  622: { description: 'silné sněhové přeháňky', icon: '13d' },
  701: { description: 'mlha', icon: '50d' },
  711: { description: 'kouř', icon: '50d' },
  721: { description: 'opar', icon: '50d' },
  731: { description: 'písečné/prachové vichřice', icon: '50d' },
  741: { description: 'mlha', icon: '50d' },
  751: { description: 'písek', icon: '50d' },
  761: { description: 'prach', icon: '50d' },
  762: { description: 'vulkanický popel', icon: '50d' },
  771: { description: 'skřehotání', icon: '50d' },
  781: { description: 'tornádo', icon: '50d' },
  800: { description: 'jasno', icon: '01d' },
  801: { description: 'málo oblačno: 11-25%', icon: '02d' },
  802: { description: 'polojasno: 25-50%', icon: '03d' },
  803: { description: 'zataženo: 51-84%', icon: '04d' },
  804: { description: 'oblačno: 85-100%', icon: '04d' }
};

export const rainWeatherIds = [
  500, // Lehký déšť
  501, // Mírný déšť
  502, // Silný déšť
  503, // Velmi silný déšť
  504, // Extrémní déšť
  511, // Mrznoucí déšť
  520, // Lehké přeháňky
  521, // Přeháňky
  522, // Silné přeháňky
  531 // Nepravidelné přeháňky
];

export const MIN_TEMPERATURE = -30;
export const MAX_TEMPERATURE = 40;
