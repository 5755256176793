<template>
  <div>
    <AutoComplete
      class="input"
      dropdown
      optionLabel="name"
      @complete="search"
      v-model="value"
      dropdownMode="current"
      :suggestions="items"
    />
  </div>
</template>

<script>
// import Autocomplete from 'vue3-autocomplete';
import AutoComplete from 'primevue/autocomplete';
import axios from 'axios';
// Optional: Import default CSS
//import 'vue3-autocomplete/dist/vue3-autocomplete.css';
export default {
  name: 'LocationPicker',
  components: {
    AutoComplete,
  },
  data() {
    return {
      items: [],
      value: this.selectedItem,
    };
  },
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  watch: {
    value(newValue) {
      this.$emit('onLocationChange', newValue);
    },
  },
  methods: {
    async search(event) {
      const { data } = await axios.get(
        '/.netlify/functions/suggest?query=' + event.query
      );
      this.items = data.map((item) => {
        const name = item.data.regionalStructure
          .filter((struc) => struc.type !== 'regional.country')
          .reduce((acc, curr) => {
            return acc.concat(curr.name);
          }, []);
        return {
          name: name.join(' | '),
          position: item.data.position,
        };
      });
    },
  },
};
</script>

<style scoped>
.input {
  max-width: 700px;
  width: 80%;
}
</style>
