<template>
  <div class="about-panel">
    <h2>K čemu aplikace slouží a proč vznikla</h2>
    <p>
      Aplikaci jsem původně psal pro svou dceru, která má problémy s oblékáním.
      V kombinaci s PAS má problémy chápat slovní instrukce, a i když ji
      řekneme, že si nějaké oblečení nemůže vzít, tak se stejně tisíckrát zeptá.
      Stačí, aby z kraje jara zasvítilo slunce, a ona už má léto a i přes nízké
      teploty se dožaduje plavek na ven. Na doporučení jsme doma zavedli
      obrázkové kartičky na denní režim, a ty ji velmi pomohly zvládat den.
      Jenže ohledně počasí to je složitější; museli bychom neustále kartičky
      upravovat dle aktuálního počasí, a na to není čas. Proto jsem se rozhodl
      zkusit napsat tuto aplikaci. Dcera tak může při vypravování ven nebo na
      zahradu otevřít tablet a vizuálně se podívat, jaké oblečení je vhodné si
      vzít.
    </p>

    <h2>První Verze a Testovací Provoz</h2>
    <p>
      Jde o první verzi aplikace, která je v testovacím provozu. Když jsem
      sdílel první koncept na Twitteru, ozvalo se mnoho lidí, že by se jim
      taková aplikace také hodila. Proto jsem ji udělal veřejnou a pokusil se
      zpřístupnit nastavení tak, aby každý rodič mohl přizpůsobit, co se bude
      dítěti zobrazovat. Například, aby se klukovi neukazovaly v nabídce dívčí
      šaty.
    </p>

    <h2>Přizpůsobení Nastavení</h2>
    <p>
      Každý rodič si může přizpůsobit, pro jaké teploty se konkrétní kusy
      oblečení budou ukazovat. Důležité je nastavit si oblast, ve které bydlíte.
      Podle ní se totiž stahuje aktuální teplota. Tu si můžete v nastavení
      upravit. Může se brát v úvahu skutečná teplota, nebo pocitová teplota.
      Pocitová bere v úvahu i vlhkost vzduchu a vítr, takže je pro tyto účely
      vhodnější.
    </p>

    <h2>Budoucí Vývoj a Zpětná Vazba</h2>
    <p>
      Aktuality můžete sledovat na
      <a href="https://www.facebook.com/profile.php?id=61560960234127&sk=about"
        >Facebook stránce aplikace. </a
      >Uvidím, jak se aplikace v praxi osvědčí. Budu rád, pokud se podělíte o
      své dojmy a případně i pošlete návrhy na vylepšení na e-mail
      <a href="mailto:david@nogol.cz">david@nogol.cz</a> nebo do
      <a href="https://www.facebook.com/groups/oblecsespravne"
        >této Facebook skupiny</a
      >.
    </p>
    <p>
      Děkuji za zájem o aplikaci a těším se na vaše zpětné vazby. David Nogol
    </p>
    <p><a href="https://nogol.cz">nogol.cz</a></p>
  </div>
</template>

<script>
export default {
  name: 'AboutPanel'
};
</script>

<style scoped>
.about-panel {
  max-width: 900px;
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.5;
  padding: 3px;
  text-align: justify;
}

@media (min-width: 600px) {
  .about-panel {
    font-size: 18px;
  }
}

@media (min-width: 900px) {
  .about-panel {
    font-size: 20px;
  }
}
</style>
